import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "menu" }
const _hoisted_2 = {
  class: "row no-wrap q-pa-xs",
  style: {"overflow":"hidden"}
}
const _hoisted_3 = { class: "column dropdown-column" }
const _hoisted_4 = { class: "column items-center dropdown-column" }

import { storeToRefs } from 'pinia'
import { City } from 'src/common/axios-client'
import { Slugs } from 'src/pages/public/slug.map'
import { usePreFetchStore } from 'src/stores/prefetch.store'
import { ref } from 'vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'city-selector',
  setup(__props) {

const prefetchStore = usePreFetchStore()

const { _menuCities: menuCities, _allCities: allCities } =
	storeToRefs(prefetchStore)

const isMenuOpened = ref(false)
const activeCity = ref<City>(menuCities.value[0] ?? null)
const allCitiesMode = ref(false)

async function selectCity(city: City) {
	allCitiesMode.value = false
	activeCity.value = city
}

return (_ctx: any,_cache: any) => {
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_item = _resolveComponent("q-item")!
  const _component_q_list = _resolveComponent("q-list")!
  const _component_q_scroll_area = _resolveComponent("q-scroll-area")!
  const _component_q_separator = _resolveComponent("q-separator")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_q_btn, {
      rounded: "",
      icon: "location_city",
      label: "Город",
      style: {"max-height":"460px"},
      onClick: _cache[0] || (_cache[0] = ($event: any) => (isMenuOpened.value = !isMenuOpened.value)),
      class: _normalizeClass({ selected: isMenuOpened.value })
    }, null, 8, ["class"]),
    _withDirectives(_createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_q_scroll_area, { class: "scroll-area" }, {
            default: _withCtx(() => [
              _createVNode(_component_q_list, null, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(menuCities), (city) => {
                    return (_openBlock(), _createBlock(_component_q_item, {
                      clickable: "",
                      class: _normalizeClass(["city-selector-item", {
								'selected-city': city.id === activeCity.value.id && !allCitiesMode.value,
							}]),
                      key: city.id,
                      onClick: ($event: any) => (selectCity(city))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(city.name), 1)
                      ]),
                      _: 2
                    }, 1032, ["class", "onClick"]))
                  }), 128)),
                  _createVNode(_component_q_item, {
                    clickable: "",
                    class: _normalizeClass(["city-selector-item", { 'selected-city': allCitiesMode.value }]),
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (allCitiesMode.value = true))
                  }, {
                    default: _withCtx(() => _cache[5] || (_cache[5] = [
                      _createTextVNode(" Все города ")
                    ])),
                    _: 1
                  }, 8, ["class"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _createVNode(_component_q_separator, {
          vertical: "",
          inset: "",
          class: "q-mx-xs"
        }),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_q_scroll_area, { class: "scroll-area" }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(menuCities), (city) => {
                return _withDirectives((_openBlock(), _createBlock(_component_q_list, {
                  key: city.id
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_router_link, {
                      to: `/${_unref(Slugs).CITY}/${activeCity.value.slug}`
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_q_item, {
                          clickable: "",
                          class: "city-selector-item",
                          onClick: _cache[2] || (_cache[2] = ($event: any) => (isMenuOpened.value = false))
                        }, {
                          default: _withCtx(() => _cache[6] || (_cache[6] = [
                            _createTextVNode(" Все районы ")
                          ])),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["to"]),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(city.districts, (district) => {
                      return (_openBlock(), _createBlock(_component_router_link, {
                        key: district.id,
                        to: `/${_unref(Slugs).CITY}/${activeCity.value.slug}/${_unref(Slugs).DISTRICT}/${district.slug}`
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_q_item, {
                            clickable: "",
                            class: "city-selector-item",
                            onClick: _cache[3] || (_cache[3] = ($event: any) => (isMenuOpened.value = false))
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(district.name), 1)
                            ]),
                            _: 2
                          }, 1024)
                        ]),
                        _: 2
                      }, 1032, ["to"]))
                    }), 128))
                  ]),
                  _: 2
                }, 1024)), [
                  [_vShow, !allCitiesMode.value && city.id === activeCity.value.id]
                ])
              }), 128)),
              _withDirectives(_createVNode(_component_q_list, null, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(allCities), (city) => {
                    return (_openBlock(), _createBlock(_component_router_link, {
                      key: city.id,
                      to: `/${_unref(Slugs).CITY}/${activeCity.value.slug}`
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_q_item, {
                          clickable: "",
                          class: "city-selector-item",
                          onClick: _cache[4] || (_cache[4] = ($event: any) => (isMenuOpened.value = false))
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(city.name), 1)
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1032, ["to"]))
                  }), 128))
                ]),
                _: 1
              }, 512), [
                [_vShow, allCitiesMode.value]
              ])
            ]),
            _: 1
          })
        ])
      ])
    ], 512), [
      [_vShow, isMenuOpened.value]
    ])
  ], 64))
}
}

})