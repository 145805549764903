import script from "./main-sidebar.vue?vue&type=script&setup=true&lang=ts"
export * from "./main-sidebar.vue?vue&type=script&setup=true&lang=ts"

import "./main-sidebar.vue?vue&type=style&index=0&id=4a2f352b&lang=scss"

const __exports__ = script;

export default __exports__
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import qInstall from "../../../node_modules/.pnpm/@quasar+app-webpack@3.13.4_eslint@8.57.0_pinia@2.2.2_typescript@4.9.4_vue@3.5.3_typescript@4._kdplrr3ceeinsy2ecobt4vztby/node_modules/@quasar/app-webpack/lib/webpack/runtime.auto-import.js";
qInstall(script, 'components', {QBtn,QList,QItem});
