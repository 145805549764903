import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, Fragment as _Fragment, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "flex column main col-md-8 col-sm-12 col-xs-12" }
const _hoisted_2 = { class: "flex row" }
const _hoisted_3 = { class: "flex row" }
const _hoisted_4 = { class: "app-title q-ma-sm" }
const _hoisted_5 = {
  key: 0,
  class: "flex row q-ml-auto justify-center self-center fade-in"
}
const _hoisted_6 = { class: "flex row justify-center fade-in" }
const _hoisted_7 = { class: "flex row q-ml-auto self-center justify-center gap-5 fade-in" }
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { class: "flex q-my-xs" }
const _hoisted_10 = {
  class: "flex self-center no-wrap",
  style: {"flex-grow":"1","height":"42px"}
}
const _hoisted_11 = {
  key: 0,
  class: "flex q-ml-auto justify-center fade-in"
}
const _hoisted_12 = {
  class: "main col-md-8 col-sm-12 col-xs-12",
  style: {"margin-bottom":"20px"}
}

import LanguageSwitch from 'components/common/tools/language-switch.vue'
import DarkModeToggle from 'components/common/tools/dark-mode-toggle.vue'
import NotificationBell from 'components/common/tools/notification-bell.vue'
import mainSidebar from 'src/components/public/main-sidebar.vue'
import appFooter from 'src/components/public/app-footer.vue'
import CitySelector from 'src/components/common/tools/city-selector.vue'
import { useI18n } from 'vue-i18n'
import { computed, ref } from 'vue'
import { useAuthStore } from 'stores/auth.store'
import { useUiStore } from 'stores/ui.store'
import CategoriesMenu from 'components/public/categories-menu.vue'
import { Screen } from 'quasar'
import { usePreFetchStore } from 'src/stores/prefetch.store'

export default /*@__PURE__*/_defineComponent({
  ...{
	async preFetch() {
		const prefetchStore = usePreFetchStore()
		return Promise.all([
			prefetchStore.updateFeaturesStore(),
			prefetchStore.updateCategoriesStore(),
			prefetchStore.updateCities(),
		])
	},
},
  __name: 'main-layout',
  setup(__props) {

const i18n = useI18n()

const authStore = useAuthStore()
const uiStore = useUiStore()
const search = ref('')

const drawerWidth = computed(() => {
	const clientWidth = Screen.width
	const defaultWidth = 540
	return Math.min(clientWidth, defaultWidth)
})



return (_ctx: any,_cache: any) => {
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_item = _resolveComponent("q-item")!
  const _component_q_list = _resolveComponent("q-list")!
  const _component_q_menu = _resolveComponent("q-menu")!
  const _component_q_header = _resolveComponent("q-header")!
  const _component_q_drawer = _resolveComponent("q-drawer")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_q_page_container = _resolveComponent("q-page-container")!
  const _component_q_footer = _resolveComponent("q-footer")!
  const _component_q_layout = _resolveComponent("q-layout")!
  const _directive_close_popup = _resolveDirective("close-popup")!

  return (_openBlock(), _createBlock(_component_q_layout, { view: "lHh Lpr lFf" }, {
    default: _withCtx(() => [
      _createVNode(_component_q_header, { class: "row" }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_q_btn, {
                  outline: "",
                  icon: "menu",
                  class: "sidebar-btn sidebar-btn-40x40 self-center q-ma-sm",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(uiStore).mainSidebarOpened = !_unref(uiStore).mainSidebarOpened))
                }),
                _createElementVNode("div", _hoisted_4, [
                  _createVNode(_component_router_link, { to: "/" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_unref(i18n).t('appTitle')), 1)
                    ]),
                    _: 1
                  })
                ])
              ]),
              (_unref(uiStore).uiMode === 'mobile')
                ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                    _createVNode(_component_q_btn, {
                      icon: "search",
                      rounded: "",
                      class: "sidebar-btn-40x40",
                      direction: "left"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_q_menu, { style: {"width":"75%"} }, {
                          default: _withCtx(() => [
                            _createVNode(_component_q_list, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_q_item, { style: {"padding":"0"} }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_q_input, {
                                      class: "q-px-sm self-center full-width",
                                      dense: "",
                                      modelValue: search.value,
                                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((search).value = $event)),
                                      clearable: "",
                                      type: "search",
                                      "bg-color": "dark",
                                      placeholder: _unref(i18n).t('Search')
                                    }, null, 8, ["modelValue", "placeholder"])
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]))
                : _createCommentVNode("", true),
              (_unref(uiStore).uiMode === 'desktop')
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                    _createElementVNode("div", _hoisted_6, [
                      _createVNode(_component_q_input, {
                        class: "q-ma-sm self-center",
                        dense: "",
                        modelValue: search.value,
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((search).value = $event)),
                        clearable: "",
                        rounded: "",
                        outlined: "",
                        type: "search",
                        "bg-color": "dark",
                        placeholder: _unref(i18n).t('Search')
                      }, {
                        append: _withCtx(() => [
                          _createVNode(_component_q_btn, {
                            flat: "",
                            round: "",
                            icon: "search"
                          })
                        ]),
                        _: 1
                      }, 8, ["modelValue", "placeholder"]),
                      _createVNode(_component_q_btn, {
                        icon: "ion-globe",
                        class: "sidebar-btn sidebar-btn-40 q-ma-sm self-center",
                        label: "На карте"
                      })
                    ]),
                    _createElementVNode("div", _hoisted_7, [
                      (_unref(authStore).isLoggedIn)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                            _createVNode(NotificationBell, { class: "sidebar-btn-40" })
                          ]))
                        : _createCommentVNode("", true),
                      (!_unref(authStore).isLoggedIn)
                        ? (_openBlock(), _createBlock(_component_q_btn, {
                            key: 1,
                            rounded: "",
                            flat: "",
                            class: "sidebar-btn-40 login-button",
                            icon: "login",
                            label: "Войти",
                            to: "/auth/login"
                          }))
                        : _createCommentVNode("", true),
                      _createVNode(_component_q_btn, {
                        rounded: "",
                        flat: "",
                        class: "sidebar-btn-40 add-card",
                        icon: "check",
                        label: "Добавить анкету",
                        to: "/personal/cards"
                      })
                    ])
                  ], 64))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("div", _hoisted_10, [
                _createVNode(CategoriesMenu)
              ]),
              (_unref(uiStore).uiMode === 'desktop')
                ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                    _createVNode(_component_q_btn, {
                      rounded: "",
                      icon: "ion-train",
                      label: "Метро"
                    }),
                    _createVNode(CitySelector),
                    _createVNode(LanguageSwitch),
                    _createVNode(DarkModeToggle)
                  ]))
                : _createCommentVNode("", true)
            ])
          ])
        ]),
        _: 1
      }),
      _withDirectives((_openBlock(), _createBlock(_component_q_drawer, {
        modelValue: _unref(uiStore).mainSidebarOpened,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_unref(uiStore).mainSidebarOpened) = $event)),
        dark: _unref(uiStore).darkMode,
        overlay: "",
        bordered: "",
        width: drawerWidth.value
      }, {
        default: _withCtx(() => [
          _createVNode(mainSidebar)
        ]),
        _: 1
      }, 8, ["modelValue", "dark", "width"])), [
        [_directive_close_popup]
      ]),
      _createVNode(_component_q_page_container, { class: "row" }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_12, [
            _createVNode(_component_router_view),
            _cache[4] || (_cache[4] = _createElementVNode("h2", { class: "q-mt-md" }, "Сайт проституток Ашу это:", -1)),
            _cache[5] || (_cache[5] = _createElementVNode("div", { class: "flex row features-block justify-between justify-center gap-20" }, [
              _createElementVNode("div", null, [
                _createElementVNode("p", null, "✅ Надежно"),
                _createElementVNode("p", null, "Только настоящие проверенные анкеты и объявления")
              ]),
              _createElementVNode("div", null, [
                _createElementVNode("p", null, "✅ Удобно"),
                _createElementVNode("p", null, "Большой выбор проституток на любой вкус и цену")
              ]),
              _createElementVNode("div", null, [
                _createElementVNode("p", null, "✅ Быстро"),
                _createElementVNode("p", null, "Рядом с Вами обязательно найдётся")
              ]),
              _createElementVNode("div", null, [
                _createElementVNode("p", null, "✅ Безотказно"),
                _createElementVNode("p", null, "Номера проституток указаны на сайте и они ждут вашего звонка")
              ]),
              _createElementVNode("div", null, [
                _createElementVNode("p", null, "✅ Круглосуточно"),
                _createElementVNode("p", null, "Работаем по выходным и праздникам 24 часа в сутки")
              ])
            ], -1))
          ])
        ]),
        _: 1
      }),
      _createVNode(_component_q_footer, { bordered: "" }, {
        default: _withCtx(() => [
          _createVNode(appFooter)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})