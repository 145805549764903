import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from 'assets/images/18+.svg'


const _hoisted_1 = { style: {"width":"100%"} }
const _hoisted_2 = { class: "flex row justify-center" }
const _hoisted_3 = { class: "q-mx-sm" }
const _hoisted_4 = { class: "flex row footer justify-between q-px-md" }
const _hoisted_5 = { class: "flex column justify-start col-sm-3 col-xs-12 q-pa-sm" }
const _hoisted_6 = { class: "app-title" }
const _hoisted_7 = { class: "flex column justify-start col-sm-3 col-xs-12 q-pa-sm" }
const _hoisted_8 = { class: "flex column justify-start col-sm-3 col-xs-12 q-pa-sm" }
const _hoisted_9 = { class: "flex column justify-start col-sm-3 col-xs-12 q-pa-sm" }

import { ref } from 'vue'
import { useI18n } from 'vue-i18n'


export default /*@__PURE__*/_defineComponent({
  __name: 'app-footer',
  setup(__props) {

const i18n = useI18n()

const expanded = ref(false)

return (_ctx: any,_cache: any) => {
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_item = _resolveComponent("q-item")!
  const _component_q_list = _resolveComponent("q-list")!
  const _component_q_img = _resolveComponent("q-img")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _withDirectives(_createVNode(_component_q_btn, {
        flat: "",
        icon: expanded.value ? 'keyboard_arrow_down' : 'keyboard_arrow_up',
        onClick: _cache[0] || (_cache[0] = ($event: any) => (expanded.value = false)),
        class: "full-width"
      }, null, 8, ["icon"]), [
        [_vShow, expanded.value]
      ]),
      _withDirectives(_createVNode(_component_q_btn, {
        class: "pointer q-pa-xs text-center full-width",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (expanded.value = true))
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("strong", null, _toDisplayString(_unref(i18n).t('appTitle')), 1)
            ]),
            _cache[2] || (_cache[2] = _createElementVNode("div", null, " Удобный поиск проституток, массажисток и эскорт-моделей по всей России ", -1))
          ])
        ]),
        _: 1
      }, 512), [
        [_vShow, !expanded.value]
      ])
    ]),
    _withDirectives(_createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("p", _hoisted_6, _toDisplayString(_unref(i18n).t('appTitle')), 1),
        _createElementVNode("p", null, [
          _cache[3] || (_cache[3] = _createTextVNode(" © 2021–2024, ")),
          _createElementVNode("strong", null, _toDisplayString(_unref(i18n).t('appTitle')), 1),
          _cache[4] || (_cache[4] = _createTextVNode(" — проект, созданный для удобного поиска проституток, массажисток и эскорт-моделей по всей России. "))
        ])
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createVNode(_component_q_list, null, {
          default: _withCtx(() => [
            _createVNode(_component_q_item, null, {
              default: _withCtx(() => _cache[5] || (_cache[5] = [
                _createTextVNode("Главная")
              ])),
              _: 1
            }),
            _createVNode(_component_q_item, null, {
              default: _withCtx(() => _cache[6] || (_cache[6] = [
                _createTextVNode("О проекте")
              ])),
              _: 1
            }),
            _createVNode(_component_q_item, null, {
              default: _withCtx(() => _cache[7] || (_cache[7] = [
                _createTextVNode("Реклама на сайте")
              ])),
              _: 1
            }),
            _createVNode(_component_q_item, null, {
              default: _withCtx(() => _cache[8] || (_cache[8] = [
                _createTextVNode("Фонд Ashoo")
              ])),
              _: 1
            }),
            _createVNode(_component_q_item, null, {
              default: _withCtx(() => _cache[9] || (_cache[9] = [
                _createTextVNode("HEALTH+")
              ])),
              _: 1
            }),
            _createVNode(_component_q_item, null, {
              default: _withCtx(() => _cache[10] || (_cache[10] = [
                _createTextVNode("Метро")
              ])),
              _: 1
            }),
            _createVNode(_component_q_item, null, {
              default: _withCtx(() => _cache[11] || (_cache[11] = [
                _createTextVNode("Районы")
              ])),
              _: 1
            }),
            _createVNode(_component_q_item, null, {
              default: _withCtx(() => _cache[12] || (_cache[12] = [
                _createTextVNode("Шоссе")
              ])),
              _: 1
            }),
            _createVNode(_component_q_item, null, {
              default: _withCtx(() => _cache[13] || (_cache[13] = [
                _createTextVNode("Города")
              ])),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createVNode(_component_q_list, null, {
          default: _withCtx(() => [
            _createVNode(_component_q_item, null, {
              default: _withCtx(() => _cache[14] || (_cache[14] = [
                _createTextVNode("Пользовательское соглашение для клиентов")
              ])),
              _: 1
            }),
            _createVNode(_component_q_item, null, {
              default: _withCtx(() => _cache[15] || (_cache[15] = [
                _createTextVNode("Пользовательское соглашение для рекламодателей")
              ])),
              _: 1
            }),
            _createVNode(_component_q_item, null, {
              default: _withCtx(() => _cache[16] || (_cache[16] = [
                _createTextVNode("Политика конфиденциальности")
              ])),
              _: 1
            }),
            _createVNode(_component_q_item, null, {
              default: _withCtx(() => _cache[17] || (_cache[17] = [
                _createTextVNode("Контакты")
              ])),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_9, [
        _createVNode(_component_q_list, null, {
          default: _withCtx(() => [
            _createVNode(_component_q_item, null, {
              default: _withCtx(() => [
                _createVNode(_component_q_btn, {
                  flat: "",
                  rounded: "",
                  icon: "fa-brands fa-telegram",
                  label: "Telegram"
                })
              ]),
              _: 1
            }),
            _createVNode(_component_q_item, null, {
              default: _withCtx(() => [
                _createVNode(_component_q_btn, {
                  flat: "",
                  rounded: "",
                  icon: "fa-brands fa-twitter",
                  label: "Twitter"
                })
              ]),
              _: 1
            }),
            _createVNode(_component_q_item, null, {
              default: _withCtx(() => [
                _createElementVNode("div", null, [
                  _createVNode(_component_q_img, {
                    class: "q-ml-sm",
                    loading: "lazy",
                    width: "38px",
                    height: "38px",
                    src: _imports_0
                  })
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ])
    ], 512), [
      [_vShow, expanded.value]
    ])
  ], 64))
}
}

})